/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Table,
  Button,
  Collapse,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import axios from "axios";
import myData from "../Countries.json";
import {
  FormatFullDateList,
  FormatMonthYearDateList,
  editMode,
} from "helpers/Utils.js";
import { SavePdf } from "components/Report/GeneratePdf.js";
import * as dateFns from "date-fns";
import { Doughnut, Bar } from "react-chartjs-2";
import { colorArray } from "colors.js";
import ModalMessage from "components/Message/Message.Component";
import CustomCalendar from "../components/Calendar/Calendar.component";

const campusColor = ["#6ad0b3", "#fd7c6e", "#fbc657"];

class Messages extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getMainState();
  }

  getMainState = () => {
    return {
      MessageList: [],
      modalHTMLMessageIsOpen: false,
      modalHTMLMessageDescription: "",
      modalHTMLMessageTitle: "",
      MessageId: "",
    };
  };

  render() {
    let messageList = this.state.MessageList.map((message, index) => {
      return (
        <tr
          key={index}
          onClick={this.setHTMLMessageDescriptionAndToggle.bind(
            this,
            message.title,
            message.description,
            message.id
          )}
          style={{
            cursor: "pointer",
            fontWeight: message.isRead === true ? "normal" : "bold",
          }}
        >
          <td>{dateFns.format(message.createdDate, "MM/dd/yyyy")}</td>
          <td>{message.title}</td>

          <td>{message.userName}</td>
        </tr>
      );
    });

    return (
      <>
        <div className="content">
          <Row form>
            <Table className="table table-striped">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Title</th>
                  <th>Sender</th>
                </tr>
              </thead>
              <tbody>{messageList}</tbody>
            </Table>
          </Row>
        </div>

        <ModalMessage
          isOpen={this.state.modalHTMLMessageIsOpen}
          toggle={this.toggleModalHTMLMessage.bind(this)}
          description={this.state.modalHTMLMessageDescription}
          title={this.state.modalHTMLMessageTitle}
          messageId={this.state.MessageId}
        />
      </>
    );
  }

  async componentDidMount() {
    this._refreshMessages();
  }

  _refreshMessages = async () => {
    await axios.get("students/GetMessages").then((response) => {
      let messageList = [];

      response.data.data.map((message, index) =>
        messageList.push({
          id: message.id,
          title: message.title,
          description: message.description,
          createdDate: dateFns.parseISO(message.createdDate),
          userName: message.user.name,
          isRead: message.readDate ? true : false,
          readDate: message.readDate && dateFns.parseISO(message.readDate),
        })
      );

      this.setState({ MessageList: messageList });
    });
  };

  setHTMLMessageDescriptionAndToggle(title, description, id) {
    let modalState = this.state;

    modalState.modalHTMLMessageDescription = description.includes(
      "<!DOCTYPE html>"
    )
      ? description
      : description.split("\n").map((item, key) => {
          return (
            <span key={key}>
              {item}
              <br />
            </span>
          );
        });

    modalState.MessageId = id;
    modalState.modalHTMLMessageTitle = title;
    modalState.modalHTMLMessageIsOpen = true;

    this.setState(modalState);
  }

  toggleModalHTMLMessage = () => {
    return new Promise((resolve, reject) => {
      let modalState = this.state;
      modalState.modalHTMLMessageIsOpen = !modalState.modalHTMLMessageIsOpen;
      this.setState(modalState, resolve(true));
    });
  };
}

export default Messages;
