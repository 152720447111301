/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Table,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "reactstrap";
import axios from "axios";
import { FormatFullDateList } from "helpers/Utils.js";
import confirm from "reactstrap-confirm";

class AssignmentView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      assignments: [],
      hasChanged: false,
    };
  }

  refresh() {
    return new Promise((resolve, reject) => {
      axios
        .get("students/GetAssignment?classId=" + this.props.classId)
        .then((response) => {
          let modalState = this.state;

          modalState.assignments = [];
          response.data.data.map((assignment, index) =>
            modalState.assignments.push({
              id: assignment.id,
              date: FormatFullDateList(assignment.date),
              assignmentTypeName: assignment.assignmentType.name,
              grade: assignment.assignmentStudentViewModels[0].grade,
              name: assignment.name,
              maxGrade: assignment.maxGrade,
              editMode: false,
              assignmentStudentId: assignment.assignmentStudentViewModels[0].id,
            })
          );

          this.setState(modalState, resolve(true));
        });
    });
  }

  onOpened() {
    this.refresh();
  }

  onCloseModal = () => {
    this.resetModal();
  };

  resetModal = () => {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          assignments: [],
          hasChanged: false,
        },
        resolve(true)
      );
    });
  };

  closeModalAndCallback() {
    this.props.toggleAssignmentModal();
  }

  render() {
    let assignments = this.state.assignments.map((assignment) => {
      return (
        <tr
          key={assignment.assignmentStudentId}
          style={{
            backgroundColor:
              assignment.grade < assignment.maxGrade ? "#ffe9e9" : "#f5fff5",
          }}
        >
          <td>{assignment.date}</td>
          <td className="hideColumn">{assignment.assignmentTypeName}</td>
          <td>{assignment.name}</td>

          <td>
            <strong>
              {assignment.grade + " (" + assignment.maxGrade + ")"}
            </strong>
          </td>
        </tr>
      );
    });

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Modal
                isOpen={this.props.isOpen}
                toggle={this.props.toggleAssignmentModal.bind(this)}
                centered={true}
                autoFocus={true}
                size={"lg"}
                backdrop="static"
                unmountOnClose={true}
                onOpened={this.onOpened.bind(this)}
                onClosed={this.onCloseModal}
                scrollable
              >
                <ModalHeader
                  toggle={this.props.toggleAssignmentModal.bind(this)}
                >
                  Assignment
                </ModalHeader>
                <ModalBody>
                  <Table>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th className="hideColumn">Category</th>
                        <th>Name</th>
                        <th>Grade</th>
                      </tr>
                    </thead>

                    <tbody>{assignments}</tbody>
                  </Table>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="primary"
                    onClick={this.closeModalAndCallback.bind(this)}
                  >
                    Close
                  </Button>
                </ModalFooter>
              </Modal>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default AssignmentView;
