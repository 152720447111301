/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import axios from "axios";

class ModalMessage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Modal
                isOpen={this.props.isOpen}
                toggle={this.props.toggle}
                centered={true}
                autoFocus={true}
                backdrop="static"
                size={"lg"}
                scrollable
                onOpened={this.onOpened.bind(this)}
              >
                <ModalHeader toggle={this.props.toggle}>
                  {this.props.title}
                </ModalHeader>
                <ModalBody>
                  {(this.props.description &&
                    this.props.description.includes("<!DOCTYPE html>") && (
                      <div
                        id="htmlContainer"
                        dangerouslySetInnerHTML={{
                          __html: this.props.description,
                        }}
                      />
                    )) ||
                    (this.props.description && this.props.description)}
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={this.props.toggle}>
                    Close
                  </Button>
                </ModalFooter>
              </Modal>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  onOpened() {
    this._markAsRead();
  }

  _markAsRead = async () => {
    if (this.props.messageId)
      axios.get("messages/MarkMessageAsRead?messageId=" + this.props.messageId);
  };
}

export default ModalMessage;
