/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import axios from "axios";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleSubmit = async () => {
    let user = {
      I20: this.props.i20,
    };

    this.props.toggle().then(() => {
      axios.post("users/forgotpin", user);
    });
  };

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Modal
                isOpen={this.props.isOpen}
                toggle={this.props.toggle.bind(this)}
                centered={true}
                autoFocus={true}
                size={"sm"}
                backdrop="static"
                unmountOnClose={true}
              >
                <ModalHeader toggle={this.props.toggle.bind(this)}>
                  PIN
                </ModalHeader>
                <ModalBody>
                  <Row form>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="assignmentName">
                          {this.props.i20.length === 0
                            ? "Enter your I-20 before requesting a new PIN"
                            : "Do you want to request a new PIN ?"}
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter>
                  {(this.props.i20.length === 0 && (
                    <Button color="secondary" onClick={this.props.toggle}>
                      Ok
                    </Button>
                  )) || (
                    <React.Fragment>
                      <Button color="primary" onClick={this.handleSubmit}>
                        Yes
                      </Button>
                      <Button color="secondary" onClick={this.props.toggle}>
                        No
                      </Button>
                    </React.Fragment>
                  )}
                </ModalFooter>
              </Modal>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default ForgotPassword;
