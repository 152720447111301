import React from "react";
import { Row, Card, CardBody, CardFooter, Col } from "reactstrap";

class StudentToolLink extends React.Component {
  render() {
    return (
      <Col
        md="2"
        onClick={() => window.open(this.props.url, "_blank")}
        style={{ cursor: "pointer" }}
      >
        <Card className="card-stats">
          <CardBody>
            <Row>
              <Col md="12" xs="12" style={{ textAlign: "center" }}>
                <img src={this.props.imgUrl} />
              </Col>
            </Row>
          </CardBody>
          <CardFooter style={{ textAlign: "center" }}>
            {this.props.name}
          </CardFooter>
        </Card>
      </Col>
    );
  }
}

export default StudentToolLink;
