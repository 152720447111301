/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import axios from "axios";
import * as dateFns from "date-fns";
import Select from "react-select";
import Cards from "react-credit-cards-2";
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
  formatFormData,
} from "../helpers/Utils";
import { date } from "yup";

class TestRetake extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getMainState();
  }

  getMainState = () => {
    return {
      TestList: [],
      SelectedIndex: "-1",
      ModalPaymentIsOpen: false,
      PaymentError: "",
      IsPaying: false,
      SubmittingApplication: false,
      CardInformation: {
        number: "",
        expiry: "",
        cvc: "",
        name: "",
        focused: "",
      },
      Amount: 20,
    };
  };

  render() {
    let test =
      this.state.SelectedIndex &&
      this.state.SelectedIndex !== "-1" &&
      this.state.TestList.find((x) => x.value === this.state.SelectedIndex);

    let testDescription =
      test &&
      "Original test date: " +
        dateFns.format(test.date, "MM/dd/yyyy") +
        "\n" +
        "Subject: " +
        test.subjectCode +
        "\n" +
        "Type: " +
        test.assignmentTypeName +
        "\n" +
        "Title: " +
        test.assignmentName +
        "\n" +
        "Teacher: " +
        test.teacherName +
        "\n" +
        "Available date: ";

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                {(this.state.TestList && this.state.TestList.length > 0 && (
                  <React.Fragment>
                    <CardHeader>
                      <Row form>
                        <Col md={12}>
                          <FormGroup>
                            <Label for="studentClass">
                              Available tests to retake
                            </Label>

                            <Select
                              className={"form-group"}
                              value={
                                this.state.SelectedIndex &&
                                this.state.TestList.find(
                                  (x) => x.value === this.state.SelectedIndex
                                )
                              }
                              onChange={this.handleTestChange}
                              options={this.state.TestList}
                            />
                          </FormGroup>
                        </Col>

                        <Col md={12}>
                          <FormGroup>
                            <p
                              style={{
                                whiteSpace: "pre-line",
                                fontWeight: "bold",
                                fontSize: "large",
                              }}
                            >
                              {testDescription}

                              {test && (
                                <span style={{ color: "#ff0000" }}>
                                  {dateFns.format(
                                    test.scheduledDate,
                                    "MM/dd/yyyy"
                                  )}
                                </span>
                              )}
                            </p>
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardHeader>

                    <CardBody>
                      <Button
                        color="success"
                        size="lg"
                        style={{
                          textTransform: "inherit",
                          float: "right",
                        }}
                        onClick={this.toggleModalPayment.bind(this)}
                        disabled={!this.validateForm()}
                      >
                        {"Pay U$ " + this.state.Amount.toFixed(2)}
                      </Button>
                    </CardBody>
                  </React.Fragment>
                )) || (
                  <React.Fragment>
                    <CardHeader>
                      <Row form>
                        <Col md={12}>
                          <FormGroup>
                            <h1 className="h3 mb-3 font-weight-normal">
                              No available test to be retaken.
                            </h1>
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardHeader>
                  </React.Fragment>
                )}
              </Card>
            </Col>
          </Row>
        </div>

        <div>
          <div className="content">
            <Row>
              <Col md="12">
                <Modal
                  isOpen={this.state.ModalPaymentIsOpen}
                  onRequestClose={this.toggleModalPayment.bind(this)}
                  overlayClassName="myoverlay"
                  className="mymodal"
                  shouldCloseOnOverlayClick={false}
                  shouldCloseOnEsc={false}
                  scrollable={true}
                  onClosed={this.onCloseModal}
                >
                  <ModalHeader toggle={this.props.toggle}>Payment</ModalHeader>
                  <ModalBody>
                    <div
                      className="row form-inner-area step-box"
                      style={{ paddingTop: "20px" }}
                    >
                      <div className="col-md-5">
                        <div className="wizard-form-input position-relative form-group has-float-label">
                          <div className="row form-inner-area">
                            <div className="col-md-12">
                              <Cards
                                cvc={this.state.CardInformation.cvc}
                                expiry={this.state.CardInformation.expiry}
                                focused={this.state.CardInformation.focused}
                                name={this.state.CardInformation.name}
                                number={this.state.CardInformation.number}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-7">
                        <div className="wizard-form-input position-relative form-group has-float-label">
                          <div className="row form-inner-area">
                            <div className="col-md-12">
                              <div className="wizard-form-input position-relative form-group has-float-label">
                                <label
                                  style={{ fontSize: "15px" }}
                                  className="label-input"
                                >
                                  Name
                                </label>

                                <input
                                  style={{ padding: "12px", height: "37px" }}
                                  type="text"
                                  className={`form-control `}
                                  name="name"
                                  placeholder="Name on card"
                                  value={this.state.CardInformation.name}
                                  onChange={this.handleCardInputChange.bind(
                                    this
                                  )}
                                  onFocus={this.handleInputFocus}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row form-inner-area">
                            <div className="col-md-6">
                              <div className="wizard-form-input position-relative form-group has-float-label">
                                <label
                                  style={{ fontSize: "15px" }}
                                  className="label-input"
                                >
                                  Card Number
                                </label>

                                <input
                                  style={{ padding: "12px", height: "37px" }}
                                  type="text"
                                  className={`form-control `}
                                  name="number"
                                  pattern="[\d| ]{16,22}"
                                  placeholder="0000 0000 0000 0000"
                                  value={this.state.CardInformation.number}
                                  onChange={this.handleCardInputChange.bind(
                                    this
                                  )}
                                  onFocus={this.handleInputFocus}
                                />
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="wizard-form-input position-relative form-group has-float-label">
                                <label
                                  style={{ fontSize: "15px" }}
                                  className="label-input"
                                >
                                  Expiry
                                </label>

                                <input
                                  style={{ padding: "12px", height: "37px" }}
                                  type="text"
                                  className={`form-control `}
                                  name="expiry"
                                  placeholder="MM/YY"
                                  value={this.state.CardInformation.expiry}
                                  onChange={this.handleCardInputChange.bind(
                                    this
                                  )}
                                  onFocus={this.handleInputFocus}
                                  pattern="\d\d/\d\d"
                                />
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="wizard-form-input position-relative form-group has-float-label">
                                <label
                                  style={{ fontSize: "15px" }}
                                  className="label-input"
                                >
                                  CVC
                                </label>

                                <input
                                  style={{ padding: "12px", height: "37px" }}
                                  type="text"
                                  className={`form-control `}
                                  name="cvc"
                                  placeholder="000"
                                  value={this.state.CardInformation.cvc}
                                  onChange={this.handleCardInputChange.bind(
                                    this
                                  )}
                                  pattern="\d{3,4}"
                                  onFocus={this.handleInputFocus}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {this.state.PaymentError.length > 0 && (
                      <div className="row form-inner-area  ">
                        <div className="col-md-12">
                          <div className="wizard-form-input position-relative form-group has-float-label">
                            <div className="alert alert-danger" role="alert">
                              {this.state.PaymentError}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color="primary"
                      onClick={this.toggleModalPayment.bind(this)}
                    >
                      Close
                    </Button>
                    <Button color="success" onClick={this.pay.bind(this)}>
                      {"Pay U$" +
                        (this.state.Amount && this.state.Amount.toFixed(2))}
                    </Button>
                  </ModalFooter>
                </Modal>
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }

  async componentDidMount() {
    this._refreshTestsToRetake();
  }

  _refreshTestsToRetake = async () => {
    await axios.get("students/GetTestsToRetake").then((response) => {
      let assignmentList = [];

      response.data.data.map((assignment, index) =>
        assignment.availableDatesForMissedTests.map((availableDate, index2) =>
          assignmentList.push({
            assignmentStudentId: assignment.assignmentStudentViewModels[0].id,
            date: dateFns.parseISO(assignment.date),
            value: `${index}${index2}`,
            subjectCode: assignment.classe.code,
            assignmentName: assignment.name,
            assignmentTypeName: assignment.assignmentType.name,
            teacherName: assignment.classe.teacher.name,
            scheduledDate: dateFns.parseISO(availableDate),
            label:
              "Original test date: " +
              dateFns.format(dateFns.parseISO(assignment.date), "MM/dd/yyyy") +
              "\n" +
              " | Subject: " +
              assignment.classe.code +
              "\n" +
              " | Type: " +
              assignment.assignmentType.name +
              " | Title: " +
              assignment.name +
              " | Teacher: " +
              assignment.classe.teacher.name +
              " | Available date: " +
              dateFns.format(dateFns.parseISO(availableDate), "MM/dd/yyyy"),
          })
        )
      );

      this.setState({ TestList: assignmentList });
    });
  };

  handleTestChange = (selectedOption) => {
    this.setState({ SelectedIndex: selectedOption.value });
  };

  toggleModalPayment = () => {
    return new Promise((resolve, reject) => {
      let mainState = this.state;

      mainState.ModalPaymentIsOpen = !mainState.ModalPaymentIsOpen;

      this.setState(mainState, resolve(true));
    });
  };

  handleCardInputChange = (e) => {
    const { name, value } = e.target;

    let valueResult = value;

    return new Promise((resolve, reject) => {
      if (name === "number") {
        valueResult = formatCreditCardNumber(value);
      } else if (name === "expiry") {
        valueResult = formatExpirationDate(value);
      } else if (name === "cvc") {
        valueResult = formatCVC(value);
      }

      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState);

        ApplicationObj.CardInformation[name] = valueResult;

        return { ApplicationObj };
      }, resolve(true));
    });
  };

  handleInputFocus = ({ target }) => {
    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState);

        ApplicationObj.CardInformation.focused = target.name;

        return { ApplicationObj };
      }, resolve(true));
    });
  };

  isPaying(bool) {
    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState);

        ApplicationObj.IsPaying = bool;

        return { ApplicationObj };
      }, resolve(true));
    });
  }

  isSubmitting(bool) {
    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState);

        ApplicationObj.SubmittingApplication = bool;

        return { ApplicationObj };
      }, resolve(true));
    });
  }

  finishSubmit(response) {
    const self = this;

    if (this.state.SubmittingApplication === false) {
      this.isSubmitting(true).then(async () => {
        let selectedObj = this.state.TestList.find(
          (x) => x.value == this.state.SelectedIndex
        );

        let obj = {
          AssignmentStudentId: selectedObj.assignmentStudentId,
          ScheduledDate: selectedObj.scheduledDate,
          TransactionId: response,
          Amount: this.state.Amount,
        };

        axios
          .post("students/AddTestRetake", obj)
          .then((response) => {
            self.isSubmitting(false).then(() => {
              if (response.data.success === true) {
                self._refreshTestsToRetake().then(() => {
                  self.resetMain();
                });
              } else {
                let message = response.data.messages
                  ? response.data.messages.map((error) => error).join("\n")
                  : "An internal server error has occurred.";

                self.setSubmitError(message);
              }
            });
          })
          .catch(function (error) {
            self.isSubmitting(false).then(() => {
              let message =
                "A server connection error occurred in your application. Error message: " +
                self.ErrorMessage(error);

              self.setSubmitError(message);
            });
          });
      });
    }
  }

  setPaymentError(msg) {
    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState);

        ApplicationObj.PaymentError = msg;

        return { ApplicationObj };
      }, resolve(true));
    });
  }

  setSubmitError(msg) {
    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState);

        ApplicationObj.ErrorSubmit = msg;

        return { ApplicationObj };
      }, resolve(true));
    });
  }

  pay = async (e) => {
    e.preventDefault();

    if (this.state.IsPaying === false) {
      this.isPaying(true).then(async () => {
        let self = this;

        let obj = {
          CardName: this.state.CardInformation.name,
          CardNumber: this.state.CardInformation.number,
          CardExpMonth: this.state.CardInformation.expiry.substring(0, 2),
          CardExpYear: this.state.CardInformation.expiry.substring(3, 5),
          CardCVC: this.state.CardInformation.cvc,
          Amount: this.state.Amount,
        };

        axios
          .post("students/GetPaymentId", obj)
          .then((response) => {
            self.isPaying(false).then(() => {
              if (response.data.success === true) {
                self.toggleModalPayment().then(() => {
                  self.finishSubmit(response.data.data);
                });
              } else {
                let message = response.data.messages
                  ? response.data.messages.map((error) => error).join("\n")
                  : "An internal server error has occurred.";

                self.setPaymentError(message);
              }
            });
          })
          .catch(function (error) {
            self.isPaying(false).then(() => {
              let message =
                "A server connection error occurred in your application. Error message: " +
                self.ErrorMessage(error);

              self.setPaymentError(message);
            });
          });
      });
    }
  };

  ErrorMessage(error) {
    var message = "";

    if (error.response) {
      if (error.response.data) {
        if (error.response.data.messages) {
          message = error.response.data.messages
            .map((error) => error)
            .join("\n");
        } else {
          message = error.response.data;
        }
      } else {
        message = error.message;
      }
    } else if (error.message) {
      message = error.message;
    }

    return message;
  }

  validateForm() {
    return this.state.SelectedIndex != "-1";
  }

  onCloseModal = () => {
    this.resetModal();
  };

  resetModal = () => {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          PaymentError: "",
          IsPaying: false,
          SubmittingApplication: false,
          CardInformation: {
            number: "",
            expiry: "",
            cvc: "",
            name: "",
            focused: "",
          },
        },
        resolve(true)
      );
    });
  };

  resetMain = () => {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          SelectedIndex: "-1",
          ModalPaymentIsOpen: false,
          PaymentError: "",
          IsPaying: false,
          SubmittingApplication: false,
          CardInformation: {
            number: "",
            expiry: "",
            cvc: "",
            name: "",
            focused: "",
          },
        },
        resolve(true)
      );
    });
  };
}

export default TestRetake;
